﻿// JS Dependencies: Bootstrap & JQuery
import 'bootstrap'
import 'jquery'
import 'moment'

//import "@fortawesome/fontawesome-free"
// this will let us have a global jQuery object
//window.$ = window.jQuery = require("jquery");
//global.$ = global.jQuery = require('jquery');

// Modernizr global :)
//window.Modernizr = require('modernizr');

// this loads bootstrap-sass
//require("bootstrap-loader");

// needed for datepicker
window.moment = require('moment');

// bootstrap-select component (http://silviomoreto.github.io/bootstrap-select/)
//require("bootstrap-select");



// Using the next two lines is like including partial view _ValidationScriptsPartial.cshtml
import 'jquery-validation'
import 'jquery-validation-unobtrusive'

// CSS Dependencies: Bootstrap
import 'bootstrap/dist/css/bootstrap.css'

// Custom JS imports
// ... none at the moment

// Custom CSS imports
import '../css/site.css'
import '../css/all.css'

import '../css/tempusdominus-bootstrap-4.css'
import '../js/main.js'

